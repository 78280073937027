<script setup>
import {defineProps, ref, onMounted, defineEmits, computed} from 'vue'
import TheModal from '@/components/ui/TheModal.vue'
import BaseForm from '@/components/BaseForm.vue'
import ApiErrors from '@/components/ui/ApiErrors.vue'
import {useOtherRequestStore} from '@/views/ad-account-requests/other-requests/OtherRequestStore'
import {storeToRefs} from 'pinia'
import {useAuthenticationStore} from '@/stores'
import InputPreloader from '@/components/ui/InputPreloader.vue'
import TheSelect from '@/components/ui/TheSelect.vue'
import {useCompanyStore} from '@/views/companies/CompanyStore'
import {useNotification} from '@kyvg/vue3-notification'

const {notify} = useNotification()
const {createOtherRequest, getOtherRequest} = useOtherRequestStore()
const {userCompanyHash, isCoreTeam} = storeToRefs(useAuthenticationStore())
const {companies, companiesLoading} = storeToRefs(useCompanyStore())

// Props & emits
const props = defineProps({
  activeRequest: {
    type: Object,
    required: false,
  },
  id: {
    type: String,
    default: null,
  },
})
const emit = defineEmits(['hidden', 'created'])
const modalId = props.id ?? 'other-request-modal'

// Refs
const theModal = ref()
const defaultFormData = {
  company_hash: userCompanyHash.value ?? '',
  request_type: '',
  comment: '',
}
const formData = ref(structuredClone(defaultFormData))
const loading = ref(false)
const requestLoading = ref(false)
const error = ref(null)
const types = ['Admin Access Share', 'BM Accounts Migration', 'Ad Tracking', 'Other']

// Computed
const companiesOptions = computed(() => companies.value.map(company => ({value: company.hash, label: company.title})))
const typeOptions = computed(() => types.map(item => ({value: item, label: item})))
const modalTitle = computed(() => (props.activeRequest ? `Request #${props.activeRequest.hash}` : 'Other Request'))

// Methods
onMounted(() => {
  init()

  openModal()
})

const init = () => {
  if (props.activeRequest) {
    handleGetRequest()

    return
  }

  formData.value = structuredClone(defaultFormData)
}
const openModal = () => {
  theModal.value.handleOpenModal()
}

const closeModal = () => {
  theModal.value.handleCloseModal()
}

const handleGetRequest = async () => {
  requestLoading.value = true

  try {
    let response = await getOtherRequest(props.activeRequest?.hash, {with: ['company', 'user']})

    formData.value = structuredClone(response)
    formData.value.company_hash = response.company.hash
  } catch (responseError) {
    error.value = responseError.data
  } finally {
    requestLoading.value = false
  }
}

const handleSubmit = async () => {
  loading.value = true

  let response

  try {
    response = await createOtherRequest(formData.value)
  } catch (responseError) {
    error.value = responseError.data
  } finally {
    loading.value = false
  }

  if (!response) {
    return
  }

  emit('created')
  notify({type: 'success', text: 'Request created!'})
  closeModal()
}

const emitHidden = () => {
  emit('hidden')
}
</script>

<template>
  <TheModal ref="theModal" @hidden="emitHidden" :id="modalId" :title="modalTitle">
    <base-form @on-submitted="handleSubmit">
      <div class="modal-body">
        <div v-if="isCoreTeam" class="form-group">
          <label>Company *</label>
          <input-preloader :show-preloader="companiesLoading && !formData.company_hash" name="company">
            <the-select
              placeholder="Select a Company"
              :options="companiesOptions"
              v-model="formData.company_hash"
              :disabled="props.activeRequest"
              :allow_clear="false"
              :can_deselect="false"
              required
            />
          </input-preloader>
        </div>
        <div class="form-group">
          <label>Request Type *</label>
          <input-preloader :show-preloader="requestLoading && !formData.request_type" name="Request type">
            <the-select
              :options="typeOptions"
              v-model="formData.request_type"
              :disabled="props.activeRequest"
              :allow_clear="false"
              :can_deselect="false"
              :searchable="false"
              required
            />
          </input-preloader>
        </div>
        <div class="form-group">
          <label for="comment">Comment *</label>
          <input-preloader :show-preloader="requestLoading && !formData.comment" name="Comment">
            <textarea
              v-model="formData.comment"
              id="comment"
              class="form-control"
              :disabled="props.activeRequest"
              rows="3"
              required
            ></textarea>
          </input-preloader>
        </div>

        <div v-if="error" class="form-group">
          <api-errors :error="error"></api-errors>
        </div>
      </div>
      <div class="modal-footer justify-content-between">
        <div>
          <button v-if="!props.activeRequest" class="btn btn-primary" :disabled="loading">Submit</button>
        </div>
        <button type="button" class="btn btn-secondary" @click="closeModal">Close</button>
      </div>
    </base-form>
  </TheModal>
</template>
