import ClipboardJS from 'clipboard'
import {useNotification} from '@kyvg/vue3-notification'
import {useRoute, useRouter} from 'vue-router'
import {computed} from 'vue'
import DOMPurify from 'dompurify'
import {accessRules} from '@/plugins/accessControl'

const {notify} = useNotification()

export function notifyErrors(response) {
  notify({type: 'error', text: response.message})

  if (response.errors) {
    notify({type: 'error', text: getValidationError(response.errors)})
  }
}

export function getValidationError(errors) {
  let formatted = ''

  for (let field in errors) {
    for (let item in errors[field]) {
      if (errors[field][item] !== '') {
        formatted += errors[field][item] + '\n'
      }
    }
  }

  return formatted
}

export function formatSum(sum, toIntIfNoPlaces = true, currency = '$') {
  sum = sum * 1

  let formatted = sum,
    isNegative = sum < 0

  if (isNegative) {
    formatted = Math.abs(sum)
  }

  if (toIntIfNoPlaces) {
    formatted = toIntegerIfNoPlaces(formatted)
  }

  let decimals = formatted % 1 || !toIntIfNoPlaces ? 2 : 0

  formatted = number_format(formatted, decimals, '.', ',')

  return isNegative ? `-${currency}${formatted}` : `${currency}${formatted}`
}

export function toIntegerIfNoPlaces(value) {
  return isInteger(parseFloat(value)) ? parseInt(value) : value
}

export function isInteger(value) {
  if (typeof value !== 'number') {
    return false
  }

  return Math.floor(value) === value
}

export function copyToClipboard() {
  new ClipboardJS('.copy-btn')
}

export function deleteEmptyObjectKeys(obj) {
  Object.keys(obj).forEach(key => {
    if (obj[key] === null || obj[key] === '' || obj[key] === undefined) {
      delete obj[key]
    }
  })

  return obj
}

export const http_build_query = function (formdata, numeric_prefix, arg_separator, url_decode) {
  //  discuss at: http://phpjs.org/functions/http_build_query/
  // original by: Kevin van Zonneveld (http://kevin.vanzonneveld.net)
  // improved by: Legaev Andrey
  // improved by: Michael White (http://getsprink.com)
  // improved by: Kevin van Zonneveld (http://kevin.vanzonneveld.net)
  // improved by: Brett Zamir (http://brett-zamir.me)
  //  revised by: stag019
  //    input by: Dreamer
  // bugfixed by: Brett Zamir (http://brett-zamir.me)
  // bugfixed by: MIO_KODUKI (http://mio-koduki.blogspot.com/)
  //        note: If the value is null, key and value are skipped in the http_build_query of PHP while in phpjs they are not.
  //  depends on: urlencode
  //   example 1: http_build_query({foo: 'bar', php: 'hypertext processor', baz: 'boom', cow: 'milk'}, '', '&amp;');
  //   returns 1: 'foo=bar&amp;php=hypertext+processor&amp;baz=boom&amp;cow=milk'
  //   example 2: http_build_query({'php': 'hypertext processor', 0: 'foo', 1: 'bar', 2: 'baz', 3: 'boom', 'cow': 'milk'}, 'myvar_');
  //   returns 2: 'myvar_0=foo&myvar_1=bar&myvar_2=baz&myvar_3=boom&php=hypertext+processor&cow=milk'

  var value,
    key,
    tmp = []

  if (url_decode === undefined) url_decode = true

  var _http_build_query_helper = function (key, val, arg_separator) {
    var k,
      tmp = []
    if (val === true) {
      val = '1'
    } else if (val === false) {
      val = '0'
    }
    if (val != null) {
      if (typeof val === 'object') {
        for (k in val) {
          if (val[k] != null) {
            tmp.push(_http_build_query_helper(key + '[' + k + ']', val[k], arg_separator))
          }
        }
        return tmp.join(arg_separator)
      } else if (typeof val !== 'function') {
        if (url_decode) {
          return encodeURIComponent(key) + '=' + encodeURIComponent(val)
        } else {
          return key + '=' + val
        }
      } else {
        throw new Error('There was an error processing for http_build_query().')
      }
    } else {
      return ''
    }
  }

  if (!arg_separator) {
    arg_separator = '&'
  }
  for (key in formdata) {
    value = formdata[key]
    if (numeric_prefix && !isNaN(key)) {
      key = String(numeric_prefix) + key
    }
    var query = _http_build_query_helper(key, value, arg_separator)
    if (query !== '') {
      tmp.push(query)
    }
  }

  return tmp.join(arg_separator)
}

export const number_format = function (number, decimals = 2, dec_point = '.', thousands_sep = ',') {
  //  discuss at: http://phpjs.org/functions/number_format/
  // original by: Jonas Raoni Soares Silva (http://www.jsfromhell.com)
  // improved by: Kevin van Zonneveld (http://kevin.vanzonneveld.net)
  // improved by: davook
  // improved by: Brett Zamir (http://brett-zamir.me)
  // improved by: Brett Zamir (http://brett-zamir.me)
  // improved by: Theriault
  // improved by: Kevin van Zonneveld (http://kevin.vanzonneveld.net)
  // bugfixed by: Michael White (http://getsprink.com)
  // bugfixed by: Benjamin Lupton
  // bugfixed by: Allan Jensen (http://www.winternet.no)
  // bugfixed by: Howard Yeend
  // bugfixed by: Diogo Resende
  // bugfixed by: Rival
  // bugfixed by: Brett Zamir (http://brett-zamir.me)
  //  revised by: Jonas Raoni Soares Silva (http://www.jsfromhell.com)
  //  revised by: Luke Smith (http://lucassmith.name)
  //    input by: Kheang Hok Chin (http://www.distantia.ca/)
  //    input by: Jay Klehr
  //    input by: Amir Habibi (http://www.residence-mixte.com/)
  //    input by: Amirouche
  //   example 1: number_format(1234.56);
  //   returns 1: '1,235'
  //   example 2: number_format(1234.56, 2, ',', ' ');
  //   returns 2: '1 234,56'
  //   example 3: number_format(1234.5678, 2, '.', '');
  //   returns 3: '1234.57'
  //   example 4: number_format(67, 2, ',', '.');
  //   returns 4: '67,00'
  //   example 5: number_format(1000);
  //   returns 5: '1,000'
  //   example 6: number_format(67.311, 2);
  //   returns 6: '67.31'
  //   example 7: number_format(1000.55, 1);
  //   returns 7: '1,000.6'
  //   example 8: number_format(67000, 5, ',', '.');
  //   returns 8: '67.000,00000'
  //   example 9: number_format(0.9, 0);
  //   returns 9: '1'
  //  example 10: number_format('1.20', 2);
  //  returns 10: '1.20'
  //  example 11: number_format('1.20', 4);
  //  returns 11: '1.2000'
  //  example 12: number_format('1.2000', 3);
  //  returns 12: '1.200'
  //  example 13: number_format('1 000,50', 2, '.', ' ');
  //  returns 13: '100 050.00'
  //  example 14: number_format(1e-8, 8, '.', '');
  //  returns 14: '0.00000001'

  number = (number + '').replace(/[^0-9+\-Ee.]/g, '')
  var n = !isFinite(+number) ? 0 : +number,
    prec = !isFinite(+decimals) ? 0 : Math.abs(decimals),
    sep = typeof thousands_sep === 'undefined' ? ',' : thousands_sep,
    dec = typeof dec_point === 'undefined' ? '.' : dec_point,
    s = '',
    toFixedFix = function (n, prec) {
      var k = Math.pow(10, prec)
      return '' + (Math.round(n * k) / k).toFixed(prec)
    }
  // Fix for IE parseFloat(0.55).toFixed(0) = 0;
  s = (prec ? toFixedFix(n, prec) : '' + Math.round(n)).split('.')
  if (s[0].length > 3) {
    s[0] = s[0].replace(/\B(?=(?:\d{3})+(?!\d))/g, sep)
  }
  if ((s[1] || '').length < prec) {
    s[1] = s[1] || ''
    s[1] += new Array(prec - s[1].length + 1).join('0')
  }
  return s.join(dec)
}

export const insertValueInUrl = function (param, value) {
  if (param === undefined) {
    console.error('param is undefined')
    return false
  }

  if (value === undefined) {
    console.error('value is undefined')
    return false
  }

  if (location.search == '') {
    history.pushState('', '', location.pathname + '?' + param + '=' + value)
    return true
  }

  if (!hasParamInUrl(param)) {
    history.pushState('', '', location.pathname + location.search + '&' + param + '=' + value)
    return true
  } else {
    replaceQueryParam(param, value)
    return true
  }
}

function hasParamInUrl(param) {
  return getUrlVars()[param] !== undefined
}

export const getUrlVars = function () {
  var vars = {}
  var href = decodeURI(window.location.href)
  var answer_position = href.indexOf('?')
  var hash_position = href.indexOf('#')

  if (answer_position < 0 && hash_position > 0) {
    href = href.replace('#', '?')
    answer_position = hash_position
  }
  if (answer_position > 0 && hash_position > 0) {
    href = href.replace('#', '&')
  }
  var hashes = href.slice(answer_position + 1).split('&')
  for (var i = 0; i < hashes.length; i++) {
    var part = hashes[i].split('=')
    // vars.push(part[0]);
    vars[part[0]] = part[1]
  }

  return vars
}

export function replaceQueryParam(param, value) {
  if (param === undefined) {
    console.error('param is undefined')
    return false
  }

  if (value === undefined) {
    console.error('value is undefined')
    return false
  }

  var query_string = location.search
  var reg = new RegExp('([?|&])' + param + '=(.*?)[^&]*', 'gi')

  //Замена
  var new_query_string = query_string.replace(reg, '$1' + param + '=' + value)
  if (new_query_string == '') {
    history.pushState('', '', '?' + param + '=' + value)
  } else {
    //Запись в адресную строку
    setQueryString(new_query_string.substr(1))
  }

  return true
}

export function setQueryString(query) {
  if (typeof query !== 'string') {
    return
  }

  if (query == '') {
    return
  }

  if (history.replaceState) history.replaceState(null, null, '?' + query)
  else location.href = '#' + query
}

export function formatCreatedAt(created_at) {
  return typeof created_at === 'string' ? created_at.split(' ').shift() : ''
}

export function setPageTitle(route) {
  document.title = route.meta?.title ? route.meta.title + ' - PointBoard' : 'PointBoard'
}

export function isLocal() {
  return process.env.VUE_APP_ENV === 'local'
}

export function isDev() {
  return process.env.VUE_APP_ENV === 'dev'
}

export function isStaging() {
  return process.env.VUE_APP_ENV === 'staging'
}

export function isProduction() {
  return process.env.VUE_APP_ENV === 'production'
}

export function openUrl(url) {
  window.open(url, '_blank', 'noopener,noreferrer')
}

export function setOnBeforeUnloadCallback() {
  window.onbeforeunload = function (e) {
    e.preventDefault
    return 'Reloaded'
  }
}

export function resetOnBeforeUnloadCallback() {
  return (window.onbeforeunload = null)
}

export function removeObjectFromArray(index, array) {
  array.splice(index, 1)
}

export function loadExternalScript(url) {
  let script = document.createElement('script')
  script.setAttribute('src', url)
  document.head.appendChild(script)
}

export function isEqualTo(a, b) {
  return a === b
}

export function callManyActions(fn, amount, delay = 1000) {
  let counter = 0
  return function (...args) {
    let timer = setInterval(() => {
      if (amount === counter) {
        clearInterval(timer)
        console.log('FINISHED')
      } else {
        counter++
        console.log(counter)
        fn.apply(this, ...args)
      }
    }, delay)
  }
}

export function getClickUpListId(id) {
  if (process.env.VUE_APP_ENV === 'production') {
    return id
  }

  return 901203242481
}

export function getClickupStatus(status) {
  if (process.env.VUE_APP_ENV === 'production') {
    return status
  }

  return undefined
}

export function getClickUpEmployeeId(id) {
  return isProduction() ? id : 44307022 // Admin Point2Web
}

export function hideElementIfExist(element_id) {
  if (document.getElementById(element_id)) {
    document.getElementById(element_id).style.display = 'none'
  }
}

export function showElementIfExist(element_id) {
  if (document.getElementById(element_id)) {
    document.getElementById(element_id).style.display = 'block'
  }
}

export function getCompanyInvoicePdf(invoice_hash) {
  window.open(`${process.env.VUE_APP_API_URL}/company-invoices/${invoice_hash}/pdf`)
}

export function formatCapitalizeAndSplit(value) {
  let words = value.split('_')

  let formattedValue = words.map(word => word.charAt(0).toUpperCase() + word.slice(1)).join(' ')

  return formattedValue
}

export function getFirstTwoInitials(str) {
  const words = str.trim().split(/\s+/)

  const firstWord = words[0] || ''
  const secondWord = words[1] || ''

  return firstWord.charAt(0).toUpperCase() + secondWord.charAt(0).toUpperCase()
}

export function formatPlatformToLowercase(platform) {
  if (['Facebook', 'facebook'].includes(platform)) {
    return 'meta'
  }

  if (['Microsoft', 'microsoft'].includes(platform)) {
    return 'bing'
  }

  return platform.toLowerCase()
}

export function formatBingToMicrosoftLowercase(platform) {
  if (['Bing', 'bing'].includes(platform)) {
    return 'microsoft'
  }

  if (['Facebook', 'facebook'].includes(platform)) {
    return 'meta'
  }

  return platform.toLowerCase()
}

export function formatPlatformToLabel(platform) {
  if (['Facebook', 'facebook'].includes(platform)) {
    return 'Meta'
  }

  if (['Microsoft', 'microsoft', 'Bing', 'bing'].includes(platform)) {
    return 'Microsoft Ads'
  }

  if (['Newsbreak', 'newsbreak'].includes(platform)) {
    return 'NewsBreak'
  }

  if (['Tiktok', 'tiktok'].includes(platform)) {
    return 'TikTok'
  }

  if (['Bigo', 'bigo'].includes(platform)) {
    return 'Bigo Ads'
  }

  return formatCapitalizeAndSplit(platform)
}

export function filterKeysByDefaults(data, defaults) {
  return Object.keys(data).reduce((acc, key) => {
    if (key in defaults) {
      acc[key] = data[key]
    }
    return acc
  }, {})
}

export function isRouteActive(targetName) {
  const route = useRoute()
  const router = useRouter()

  return computed(() => {
    if (route.name === targetName) {
      return true
    }

    let currentMeta = route.meta
    while (currentMeta?.parent) {
      if (currentMeta.parent === targetName) {
        return true
      }

      // Find the parent route from all registered routes
      const parentRoute = router.getRoutes().find(record => record.name === currentMeta.parent)
      currentMeta = parentRoute?.meta
    }

    return false
  })
}

export function filterNumbers(value) {
  return String(value).replace(/[^\d.-]+/g, '')
}

export function isImageType(fileType) {
  return fileType && fileType.startsWith('image/')
}

export function isVideoType(fileType) {
  return fileType && fileType.startsWith('video/')
}

export function isValidEmail(email) {
  const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/
  return emailRegex.test(email)
}

export function toLowercaseAndJoin(string) {
  return string.toLowerCase().split(' ').join('_')
}

export function getClientPreModerationList(list_id) {
  if (isLocal() || isDev() || isStaging()) {
    return '901203242481'
  }

  return list_id
}

export function detectLinks(text) {
  const linkRegex = /(https?:\/\/[^\s]+)/g
  const rawHtml = text.replace(linkRegex, url => {
    return `<a href="${url}" class="text-info" target="_blank" rel="nofollow noopener noreferrer">${url}</a>`
  })
  const sanitizedHtml = DOMPurify.sanitize(rawHtml, {
    ALLOWED_ATTR: ['href', 'class', 'rel', 'target'],
  })
  return sanitizedHtml
}

export function findRouteByName(routeName) {
  const router = useRouter()

  const searchRoutes = routes => {
    for (const route of routes) {
      if (route.name === routeName) {
        return route
      }
      if (route.children) {
        const found = searchRoutes(route.children)
        if (found) return found
      }
    }
    return null
  }

  return searchRoutes(router.options.routes)
}

export function findFirstAvailableRoute(parent_name, auth) {
  const parentRoute = findRouteByName(parent_name)

  if (!parentRoute) {
    return null
  }

  if (Array.isArray(parentRoute.children) && parentRoute.children.length > 0) {
    const availableChild = parentRoute.children.find(child => {
      const accessRulesList = child.meta?.access

      if (!accessRulesList || accessRulesList.length === 0) {
        return true
      }

      return accessRulesList.every(rule => accessRules[rule]?.(auth))
    })

    if (availableChild) {
      return availableChild
    }
  }

  return parentRoute
}
