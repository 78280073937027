<script setup>
import {computed, defineEmits, defineProps, onMounted, ref, nextTick, watch} from 'vue'
import {storeToRefs} from 'pinia'
import {useAuthenticationStore} from '@/stores'
import BaseForm from '@/components/BaseForm.vue'
import {Modal as BaseModal} from 'bootstrap'
import TheSelect from '@/components/ui/TheSelect.vue'
import InputPreloader from '@/components/ui/InputPreloader.vue'
import {useCompanyStore} from '@/views/companies/CompanyStore'
import TheSelectAdAccounts from '@/components/ui/TheSelectAdAccounts.vue'
import PlatformOptions from '@/views/ad-account-requests/PlatformOptions'
import {useSharePermissionsRequestStore} from '@/views/ad-account-requests/share-permissions/SharePermissionsRequestStore'
import ApiErrors from '@/components/ui/ApiErrors.vue'
import TheSelectBusinessManagers from '@/views/assets/business-managers/components/TheSelectBusinessManagers.vue'
import TheSelectMultiple from '@/components/ui/TheSelectMultiple.vue'
import {isValidEmail} from '@/plugins/helpers'

// Emits & props
const emit = defineEmits(['created', 'hidden'])
const props = defineProps({
  id: {
    type: String,
    default: null,
  },
})

const modalId = props.id ?? 'share-request-modal'
const modal = ref()
const error = ref()
const selectAdAccount = ref()
const selectBusinessManagers = ref()
const {userCompanyHash, isCoreTeam} = storeToRefs(useAuthenticationStore())
const {createSharePermissionsRequest} = useSharePermissionsRequestStore()
const {companies, companiesLoading} = storeToRefs(useCompanyStore())

const loading = ref(false)

const defaultFormData = {
  company_hash: userCompanyHash.value ?? '',
  platform: '',
  type: 'ad_account',
  grant_to: [],
  revoke_from: [],
  business_manager_hashes: [],
  ad_account_ids: [],
}
const formData = ref(structuredClone(defaultFormData))

const platformOptions = structuredClone(PlatformOptions)

platformOptions.forEach(function (item, index) {
  if (item.value === 'facebook') {
    item.value = 'meta'
  }
  platformOptions[index] = item
})

// Computed
const companiesOptions = computed(() => companies.value.map(company => ({value: company.hash, label: company.title})))

// Methods
onMounted(() => {
  let modalElement = document.getElementById(modalId)
  modal.value = new BaseModal(modalElement)

  modalElement.addEventListener('hidden.bs.modal', () => {
    emit('hidden')
  })

  openModal()
})

const openModal = () => {
  modal.value.show()
}

const closeModal = () => {
  modal.value.hide()
}

const onCompanyChange = () => {
  selectBusinessManagers.value?.handleSearch('  ')

  onPlatformChanged(formData.value.platform)
}

const onPlatformChanged = value => {
  if (value === null) {
    return
  }

  if (!['meta', 'bing'].includes(value) && formData.value.type === 'business_manager') {
    formData.value.type = 'ad_account'
  }

  nextTick(() => {
    selectBusinessManagers.value?.handleSearch('  ')
    selectAdAccount.value?.handleSearch('  ')
  })

  formData.value.ad_account_ids.length = 0
  formData.value.business_manager_hashes.length = 0
}

const changeType = value => {
  formData.value.type = value

  if (value === 'business_manager') {
    formData.value.ad_account_ids.length = 0
    return
  }
  if (value === 'ad_account') {
    formData.value.business_manager_hashes.length = 0
  }

  nextTick(() => {
    selectBusinessManagers.value?.handleSearch('  ')
    selectAdAccount.value?.handleSearch('  ')
  })
}

const handleCreate = async () => {
  loading.value = true
  let payload = structuredClone(formData.value)

  if (payload.platform === 'meta') {
    payload.platform = 'facebook'
  }

  try {
    await createSharePermissionsRequest(payload)
  } catch (responseError) {
    error.value = responseError.data
    return false
  } finally {
    loading.value = false
  }

  closeModal()
  emit('created')
  formData.value = structuredClone(defaultFormData)
}

const formatEmails = values => {
  return values.map(email => email.trim().toLowerCase()).filter(email => isValidEmail(email))
}
const arraysEqual = (a, b) => {
  if (a.length !== b.length) return false
  return a.every((value, index) => value === b[index])
}

watch(
  () => formData.value.grant_to,
  newValues => {
    const sanitizedValues = formatEmails(newValues)
    if (!arraysEqual(newValues, sanitizedValues)) {
      formData.value.grant_to = sanitizedValues
    }
  },
  {deep: true},
)

watch(
  () => formData.value.revoke_from,
  newValues => {
    const sanitizedValues = formatEmails(newValues)
    if (!arraysEqual(newValues, sanitizedValues)) {
      formData.value.revoke_from = sanitizedValues
    }
  },
  {deep: true},
)
</script>

<template>
  <div class="modal fade" :id="modalId" tabindex="-1" aria-hidden="true">
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title">Share Access Request</h5>
          <button type="button" class="btn-close" aria-label="Close" @click="closeModal" />
        </div>

        <base-form @on-submitted="handleCreate">
          <div class="modal-body">
            <div v-if="isCoreTeam" class="form-group">
              <label>Company *</label>
              <input-preloader :show-preloader="companiesLoading && !formData.company_hash" name="company">
                <the-select
                  id="company"
                  name="company_hash"
                  placeholder="Select a Company"
                  :options="companiesOptions"
                  v-model="formData.company_hash"
                  @change="onCompanyChange"
                  :can_deselect="false"
                  :allow_clear="false"
                  required
                />
              </input-preloader>
            </div>

            <div class="form-group">
              <label>Platform *</label>
              <input-preloader name="platform">
                <the-select
                  id="platform"
                  name="platform"
                  @change="onPlatformChanged"
                  placeholder="Select a platform"
                  :options="platformOptions"
                  v-model="formData.platform"
                  :allow_clear="false"
                  :can_deselect="false"
                  required
                />
              </input-preloader>
            </div>

            <div class="form-group">
              <div class="btn-group" role="group">
                <button
                  @click="changeType('ad_account')"
                  :class="['btn btn-secondary', formData.type === 'ad_account' ? 'active' : '']"
                  type="button"
                >
                  Ad account
                </button>
                <button
                  @click="changeType('business_manager')"
                  :disabled="!['meta', 'bing'].includes(formData.platform)"
                  :class="['btn btn-secondary', formData.type === 'business_manager' ? 'active' : '']"
                  type="button"
                >
                  Business Manager
                </button>
              </div>
            </div>

            <div v-if="formData.type === 'ad_account'" class="form-group">
              <label>Ad account *</label>
              <the-select-ad-accounts
                v-model="formData.ad_account_ids"
                :list-by-search="!!userCompanyHash"
                :payload="{
                  platform: formData.platform,
                  company_hash: formData.company_hash,
                }"
                :multiple="true"
                :disabled="formData.company_hash === undefined || !formData.platform"
                ref="selectAdAccount"
                required
              ></the-select-ad-accounts>
            </div>
            <div
              v-if="formData.type === 'business_manager' && ['meta', 'bing'].includes(formData.platform)"
              class="form-group"
            >
              <label>Business manager *</label>
              <the-select-business-managers
                v-model="formData.business_manager_hashes"
                :list-by-search="!!userCompanyHash"
                :platform="formData.platform"
                :payload="{
                  company_hash: formData.company_hash,
                }"
                :disabled="formData.company_hash === undefined || !formData.platform"
                :with-appended="false"
                mode="tags"
                ref="selectBusinessManagers"
                required
              >
              </the-select-business-managers>
            </div>
            <div class="form-group">
              <label>Grant access to</label>
              <the-select-multiple
                :options="formData.grant_to"
                v-model="formData.grant_to"
                placeholder="Enter email to grant access"
                :create_option="true"
                :show_no_results="false"
                :show_options="false"
                mode="tags"
                :can-add-tags="true"
                :required="formData.revoke_from.length === 0"
              />
            </div>
            <div class="form-group">
              <label>Revoke access from</label>
              <the-select-multiple
                :options="formData.revoke_from"
                v-model="formData.revoke_from"
                placeholder="Enter email to revoke access"
                :create_option="true"
                :show_no_results="false"
                :show_options="false"
                mode="tags"
                :can-add-tags="true"
                :required="formData.grant_to.length === 0"
              />
            </div>
            <div class="form-group">
              <label>Comment</label>
              <textarea v-model="formData.comment" class="form-control" rows="2"></textarea>
            </div>
            <div v-if="error" class="mt-3">
              <api-errors :error="error"></api-errors>
            </div>
          </div>
          <div class="modal-footer justify-content-between">
            <div>
              <button type="submit" class="btn btn-primary" :disabled="loading">Submit</button>
            </div>
            <button type="button" class="btn btn-secondary" :disabled="loading" @click="closeModal">Close</button>
          </div>
        </base-form>
      </div>
    </div>
  </div>
</template>
